@font-face {
  font-family: "Roobert";
  src: url("./fonts/WEB/RoobertENEL-Regular.woff") format("woff"),
    url("./fonts/WEB/RoobertENEL-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roobert Light";
  src: url("./fonts/WEB/RoobertENEL-Light.woff") format("woff"),
    url("./fonts/WEB/RoobertENEL-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Roobert Bold";
  src: url("./fonts/WEB/RoobertENEL-Bold.woff") format("woff"),
    url("./fonts/WEB/RoobertENEL-Bold.woff2") format("woff2");
}

// @font-face {
//   font-family: "Roobert";
//   src: url("./fonts/WEB/RoobertENEL-Black.woff") format("woff"),
//     url("./fonts/WEB/RoobertENEL-Black.woff2") format("woff2");
// }

body {
  font-family: "Roobert";
  position: relative;
}

b {
  font-weight: 300 !important;
  font-family: "Roobert Bold";
}

// ul, li {
//   list-style-type: none !important;
//   padding-left: 0;
// }

#dialog-description {  
  ul, li {
    list-style-type: unset !important;
    padding-left: 0;
  }
}

.small-note {
  max-width: 730px;
  color: #131416 !important;
  li {
    list-style-type: disc !important;
    margin-left: 1rem;
  }
}

#box-risp-preliminare {
  background-color: #F4F8FF33;
  color: white;
  border-radius: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom:2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  z-index: 10;

  h2 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }

  .titolo {
    color: #B6CEFB;
  }
  .valore {
    font-size: 1.5rem;
  }

  .percentage {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #B2E6CD;
    font-size: 2rem;

  }
}

.close-button {
  font-family: "Roobert";
  font-weight: 700;
  flex-shrink: 0;
  font-size: 1rem;
  cursor: pointer;
  padding: 8px 16px;
  background: #f7f8fb;
  // border-radius: 20px;
}

.mw730 {
  max-width: 730px;
}

.skip {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  &:focus {
    height: auto;
    width: auto;
    clip: auto;
    left: 0px;
    background-color: white;
    top:0px;
    // border:2px solid red;
  }
}

.blue {
  color: #1F6CF9}
.blue-box {
  padding: 1rem;
  border-radius: 0.75rem;
  border: 2px solid #1F6CF9;
  background-color: #F4F8FF;
  max-width: 450px;
  &.w-100 {
    max-width: 100%;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

[class$="-placeholder"] {
  color: hsl(0, 0%, 30%) !important;
}

.nowrap {
  white-space: nowrap;
}

.bb1 {
  border-bottom: 1px solid #c2cddd;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.small-text {
  font-size: 0.875rem;
  color: #667790;
  font-weight: 300;
  line-height: 1rem;
}

.city-cap-picker {
  max-width: 450px;
  .cap-title {
    font-size: 1.125rem;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 150%;
  }

  .city {
    border: 6px solid transparent;
    box-sizing: border-box;
    // margin-left: -6px;
    &:hover {
      border: 6px solid #fbbfd1;

      border-radius: 8px;
    }
  }
}

#thanks-fs {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  margin: 0;
  padding: 0;
  z-index: 100000;

  &.max-eff {
    .thanks {
      overflow-y: auto;
      .col-lg-6 {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .gri-lottie {
          max-height: 100%;
        }
      }
      .container {
        h2,p {
          color: white !important
        }

      }
    }
  }
}

$purple100: #461e7d;
$purple80: rgba(70, 30, 125, 0.8);
$purple60: rgba(70, 30, 125, 0.6);
$purple40: rgba(70, 30, 125, 0.4);
$purple20: rgba(70, 30, 125, 0.2);

$newPrimary: #0047CC;

$gray100: #333333;
$gray80: #4f4f4f;
$gray60: #828282;
$gray40: #dddddd;
$gray20: #cecece;
$gray0: #f4f4f4;

$success100: #00855d;
$success80: rgba(0, 133, 93, 0.8);
$success60: rgba(0, 133, 93, 0.6);
$success40: rgba(0, 133, 93, 0.4);
$success20: rgba(0, 133, 93, 0.2);

$accent100: #d3135a;
$accent80: rgba(211, 19, 90, 0.8);
$accent60: rgba(211, 19, 90, 0.6);
$accent40: rgba(211, 19, 90, 0.4);
$accent20: rgba(211, 19, 90, 0.2);
// $accent100: #5738ff;
// $accent80: rgba(87, 56, 255, 0.8);
// $accent60: rgba(87, 56, 255, 0.6);
// $accent40: #5738ff66;
// $accent20: rgba(87, 56, 255, 0.2);

$warning100: rgba(230, 115, 0, 1);
$warning80: rgba(230, 115, 0, 0.8);
$warning60: rgba(230, 115, 0, 0.6);
$warning40: rgba(230, 115, 0, 0.4);
$warning20: rgba(230, 115, 0, 0.2);

$error100: #ed0529;
$error80: rgba(237, 5, 41, 0.8);
$error60: rgba(237, 5, 41, 0.6);
$error40: rgba(237, 5, 41, 0.4);
$error20: rgba(237, 5, 41, 0.2);

$cyan100: #28d7dc;
$cyan80: rgba(40, 215, 220, 0.8);
$cyan60: rgba(40, 215, 220, 0.6);
$cyan40: rgba(40, 215, 220, 0.4);
$cyan20: rgba(40, 215, 220, 0.2);

.black {
  font-family: "Roobert";
}

.red {
  color: #D3135A !important;
}

.no-bg {
  background: unset !important;
}

.dsy-button.link.bg-secondary {
  background: #F7F8FB !important;
}
.bg-secondary {
  background: #F7F8FB !important;
}
.br-0 {
  border-radius: 0px !important;
}

.mw344 {
  max-width: 344px !important;
}

.mw320 {
  max-width: 320px !important;
}

.mw500 {
  max-width: 500px !important;
}

.no-border {
  border: 0px !important;
  outline: none;
}

.red-border {
  border: 1px solid #ed0529;
}

[role="switch"]:focus,
[role="switch"]:hover,
[role="switch"]:focus-within {
  // padding: 2px 2px 6px 6px;
  border-width: 2px;
  outline: none;
  // background-color: #def;
  cursor: pointer;
}
.plain {
  font-weight: 400;
  background-color: white;
  font-family: "Roobert";
}
.p0 {
  padding: 0 !important;
}

.f12 {
  font-size: 0.75rem !important; 
}

.f14 {
  font-size: 0.875rem !important;
}

.f16 {
  font-size: 1rem !important;
}

.f18 {
  font-size: 1.125rem !important;
}

.f20 {
  font-size: 1.25rem !important;
}

.f24 {
  font-size: 1.5rem !important;
}

.lh14 {
  line-height: 0.875rem !important;
}

.lh150 {
  line-height: 150% !important;
}

.contact-fields.error {
  border: 1px solid #ed0529;
  border-radius: 0.5rem;
}

.shad1 {
  background: #ffffff;
  border: 1px solid #c2cddd;
  // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
  //   0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.shad2 {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.shad3 {
  background: #ffffff;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
    0px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

.shad4 {
  background: #ffffff;
  box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.15),
    0px 2px 3px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

.shad5 {
  background: #ffffff;
  box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15),
    0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

.color-secondary {
  color: #D3135A !important;

  a {
    color: #D3135A !important;
  
  }
}

.input-border {
  border: 1px solid #c2cddd !important;
  border-radius: 4px !important;
}

.risparmio-details {
  margin-top: 16px;
}

.outline-btn:focus {
  border: 4px double white !important;
}
.slider-component .stepper-container {
  display: flex;
  align-items: center;
  // margin-top: 16px;

  .stepper-label {
    display: flex;
    align-items: center;
    width: 120px;
    .stepper-img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }

  .number-selector {
    width: 112px;
    text-align: center !important;
    border: 1px solid #c2cddd;
    margin-right: 4px;
    margin-left: 4px;
    border-radius: 4px;
    padding: 0;
    height: 44px !important;
    color: #313b49;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &.error {
      border: 2px solid #ed0529;
    }
  }

  .stepper-component {
    .small {
      font-size: 0.75rem;
      color: #667790;
      width: 112px;
      margin-left: 4px;

      &.error {
        color: #ED0529;
      }
      
    }
  }

  .stepper-button {
    width: 44px;
    height: 44px;
    // padding: 8px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 0;
    background: var(--secondary-secondary-lightest, $accent100);
    color: white;
    font-size: 1rem;
    border: 4px double transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      filter: brightness(0) invert(1) grayscale(100%);
      // filter: brightness(0) invert(1);
    }

    &.disabled {
      background: var(--secondary-secondary-lightest, #dcddff);
      color: $gray100;

      img {
        filter: brightness(0%);
      }
    }
    &:focus {
      border: 4px double white;
      outline: none;
    }
  }
}
.option-icon {
  width: 24px !important;
  height: 24px !important;
  margin-right: 16px !important;
  // background-color: red;
}
.select-option-button {
  &:focus {
    .box {
      background: $accent20;

      &.active {
        border: 6px double white;
      }
    }
    outline: none;
  }
  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 16px;
    border: 1px solid var(--neutrals-neutral-light, #c2cddd);
    background: var(--neutrals-neutral-white, #fff);
    margin-top: 16px;
    height: 140px;
    width: 100%;
    div.icon {
      width: 32px;
      height: 32px;
    }

    .opt-button {
      display: none;
    }

    .opt-image {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }

    &.active {
      // background: $accent100;
      background: #f7f8fb;
      border: 1px solid #272c34;
      // border: 4px double white;

      // .opt-image {
      //   -webkit-filter: unset;
      //   filter: unset;
      // }
    }

    .opt-button {
      position: absolute;
      right: 8px;
      top: 8px;
    }

    .opt-image {
      margin: 32px 24px;
    }
  }
}
// #banner-image-container {
//   position: relative;
//   width: 100%;
//   padding-left:2rem;
//   padding-right: 2rem;
//   box-sizing: border-box;
//   background-color: rebeccapurple;
//   padding-top: 160%;
//   border: 2px solid red;
// }
//MARCO: moved to dedicated file
// #banner-image {
//   object-fit: contain;
//   img {
//     max-width: 100%;
//     position: absolute;
//     display: block;
//     z-index: 3;
//   }

//     width: 70%;
//     margin: 0 auto;
//     position: absolute;
//     left: 0;
//     right: 0;
//     // width: 10rem;
//     // height: 14.5rem;
//     object-fit: contain;
//     display: inline-block;
// }

.gen-subtitle {
  color: #667790;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0px;
}
#form-header {
  // background-color: $purple100;
  // background-image: url("/icons/houses.png");
  position: relative;

  .title-box {
    color: white;
    // padding: 12px;
    // padding-top: 20px;

    .title {
      font-weight: bold;
      font-family: "Roobert";
      font-size: 1.75rem;
    }

    .subtitle {
      font-family: "Roobert";
      font-size: 1.125rem;
    }

    .logo {
      height: 32px;
      margin-bottom: 40px;
    }
  }

  .progress-box {
    color: white;

    .progress {
      height: 8px;
      border-radius: 8px;
      margin-bottom: 14px;

      .progress-bar {
        background-color: $cyan100;
      }
    }

    .progress-block {
      height: 8px;
      background-color: #667790;
      flex-grow: 1;
      border-radius: 4px;
      margin-right: 8px;

      &.active {
        // background-color: white;
        background-color: #5C93F8;
      }

      &:last-child {
        margin-right: 0px;
      }
    }

    .step {
      font-family: "Roobert";
      font-size: 0.875rem;
      // color: #4F4F4F;
      line-height: 1.375rem;
    }

    .edit {
      text-align: right;
      color: $accent100;
      font-family: "Roobert";
      line-height: 1.375rem;
      font-size: 1rem;
      text-decoration-line: underline;

      img {
        width: 22px;
        fill: $accent100;
      }
    }
  }
}

label {
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.label-component {
  margin-top: 24px;
  margin-bottom: 24px;
  line-height: 150%;

  h2.title {
    margin-bottom: 0px;
  }
}

.selectatview-component.boxatview {
  max-width: 730px;
}

.square-button {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.label-component,
.selectatview-component,
.slider-component,
.divider-parent {
  max-width: 450px;

  &.lg {
    max-width: 730px;
  }

  &.contacts {
    .title {
      color: #131416;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }

  .contacts-bold {
    .title {
      color: #131416;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: 0.12px;
    }
  }

  &title,
  .title {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 150%;
    color: #272c34;

    box-shadow: none !important;
    padding: 0;
  }

  .subtitle,
  .description {
    color: #667790;
    font-size: 0.875rem;
    line-height: 150%;
    font-weight: 400;
  }
}

.label-component {
  max-width: unset;
}


h2.label-component,
h2.switch-text,
.slider-component h2 {
  // padding:unset;
  font-size: 1.25rem;
  line-height: 150%;
  font-weight: unset;
}

.text-component {
  padding: 12px !important;
  position: relative;
  height: 48px;

  &.filled {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  input {
    border: 0;
    font-size: 1rem;
    outline: none;
  }

  .fieldname {
    font-size: 0.75rem;
  }
}

select {
  padding-left: 16px;
  height: 48px;
  max-width: 100%;
  width: 240px;
}

.select-component {
  padding: 12px !important;
  // padding: 2px 8px;
  position: relative;
  height: 48px;
  flex: 100%;

  &.filled {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    background-color: white;
  }

  input {
    border: 0;
    font-size: 1rem;
    outline: none;
  }

  .fieldname {
    font-size: 1rem;
  }

  .select-with-value {
    .prop-name {
      font-size: 0.75rem;
      line-height: 1rem;
    }

    .prop-value {
      font-size: 1rem;
      line-height: 1.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 30px;
      // font-weight: 700;
    }
  }
}

.fw400 {
  font-weight: 400 !important;
}

.form-page-component {
  display: flex;
  flex-direction: column;
  // padding-left: 16px;
  // padding-right: 16px;
  padding-bottom: 80px;
}

.open-drawer {
  height: 18px;
  position: absolute;
  right: 15px;
  top: 13px;
  cursor: pointer;
}

.next-button-box {
  display: flex;
  border-top: 1px solid $gray40;
  height: 80px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 4;

  .back {
    // margin-left: 16px;
    margin-right: 16px;
  }

  button {
    background-color: $accent100;
    color: white;
    border-radius: 80px;
    height: 48px;
    width: 260px;
    border: 0;

    &:focus {
      border: 4px double white;
    }

    &:disabled {
      // background-color: $accent20;
      color: #667790;
      background-color: #dcddff;
    }
  }

  .btn.outline {
    // background-color: $gray60;
    width: unset;

    &:disabled {
      background-color: white;
    }

    &:focus {
      border: 4px solid $accent100;
    }
  }

  img {
    cursor: pointer;
  }

  img.disabled {
    opacity: 30%;
    cursor: default;
  }
}

.accent {
  color: $accent100;
}

.select-popup {
  z-index: 10001;
  // display:none;
  // position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 0;

  .so-non-so {
    display: flex;
    justify-content: stretch;
    flex-direction: column;
    .btn-group {
      background: white;
      border-radius: 4px;
      padding: 4px;
      font-family: "Roobert";
      box-shadow: 0px var(--shadow-size, 2px) var(--shadow-blur, 8px) 0px
        var(--shadow-color, rgba(102, 119, 144, 0.2));
    }
    button {
      background-color: white;
      color: #000103;
      border: 0;
      border-radius: 4px;
      flex-grow: 1;
      text-wrap: nowrap;

      &.sel {
        border: 1px solid $accent100;
        color: white;
        background-color: $accent100;
      }
    }
  }
  .select-option {
    height: 60px;
    border: 1px solid #c2cddd;
    border-radius: 1rem;
    margin-bottom: 0.5rem;

    &.sel {
      border: 1px solid #272c34;
    }

    .main {
      font-size: 1rem;
      line-height: 1rem;
      font-weight: bold;
      margin-left: 0.5rem;
      margin-right: 1rem;
    }
    .sub {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 0.875rem;
      margin-left: 0.5rem;
      color: #313b49;
    }

    img {
      // height: 16px;
    }
  }

  .title {
    // height: 64px;
    // border-bottom: 1px solid $gray40;
    box-shadow: 0px 0px 16px rgba(102, 119, 144, 0.4);
    z-index: 1000000;
    // min-height: 64px;

    .field-name {
      margin: 16px;
      margin-left: 0;
    }

    .accent {
      font-family: "Roobert";
      font-weight: 700;
      flex-shrink: 0;
      // text-decoration: underline;
      font-size: 1rem;
      cursor: pointer;
      padding: 8px 16px;
      background: #f7f8fb;
      // border-radius: 20px;

      // img {
      //   margin-left: 8px;
      // }
    }
  }

  .options {
    padding-bottom: 80px;
    overflow-y: auto;
    position: relative;
    padding-left: 0px;
    padding-right: 0px;
    &.disabled {
      color: #667790 !important;
      &:after {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        // background-color: rgba(0,0,0,0.1);
        position: absolute;
      }
    }
  }

  .classe-energetica-box {
    position: relative;

    .active-text {
      color: white;
      position: absolute;
      z-index: 10;
      top: 15px;
      left: 50px;
    }

    &.disabled {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
    .opt-button {
      display: none;
      position: absolute;
      // width: 24px;
      // height: 24px;
      // left: 27px;
      // top: 15px;

      // width: 26px;
      // height: 26px;
      // left: 29px;
      // top: 17px;

      height: 24px;
      left: 15px;
      position: absolute;
      top: 15px;
      width: 24px;
    }

    .opt-image {
      margin-bottom: 8px;
      max-width: 100%;
      width: 336px;
    }

    .blue-box-ce {
      background: $accent100;
      position: absolute;
      left: 0px;
      top: 0;
      bottom: 8px;
      width: 85px;
    }
  }

  .field-name {
    font-family: "Roobert";
    font-weight: 700;
    color: #333333;
    font-size: 1.375rem;
  }

  .confirm {
    // border-top: 1px solid $gray40;
    height: 80px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
      360deg,
      #ffffff 0%,
      #ffffff 82.97%,
      rgba(255, 255, 255, 0) 103.45%
    );
    border-top: 0;

    button {
      background-color: $accent100;
      color: white;
      border-radius: 80px;
      height: 48px;
      width: 260px;
      border: 0;
      margin-left: 16px;

      &:disabled {
        background-color: $accent20;
      }

      &:focus,
      &:hover {
        border: 4px double white;
      }
    }
  }
}

.switch-component {
  min-height: 48px;
  position: relative;
  align-items: center;

  &.disab {
    color: $gray80;
  }

  .br1 {
    border-right: 1px solid #c2cddd;
  }
  a {
    color: #313b49;
    text-decoration: none;
  }
  
  &.config-riscaldamento {
    .info-button {
      right: -42px;
      top: 11px;
    }
  }

  .mobile {
    .info-button {
      right: 0px;
      top: 0px;
    }
  }
  .external-link {
    height: 32px;
    width: 32px;
    // background: ;
    border-radius: 8px;
    padding: 8px;

    background: url("/icons/icon/dropdownarrow.svg") #eff2f7;
    background-repeat: no-repeat;
    background-position: center;
    // position: absolute;
    // right: 16px;
    // top: 11px;
    a {
      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  img {
    cursor: pointer;
  }

  .description {
    font-size: 0.875rem;
    color: #667790;
    padding-right: 16px;
  }

  label {
    text-align: left;
  }


}


.slider-component {
  position: relative;

  .disabled {
    opacity: 0.5;
  }

  .small-text {
    font-size: 0.875rem;
    color: #667790;
  }

  .slideroverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
  }

  input[type="number"] {
    height: 24px;
    padding: 16px;
    font-size: 1rem;
    outline: none;
    border: 0;
    color: $accent100;
    padding-right: 0;
    font-weight: 700;
    // width: 200px;
    min-width: 60px;
    text-align: right;
    background-color: white;

    // &:disabled {
    //   background-color: #EFF2F7;
    // }

    &.error {
      border: 1px solid #ed0529;
    }
  }

  ::marker {
    height: 100px;
    width: 100px;
    background-color: red;
  }

  input[type="range"] {
    height: 20px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
  }

  input[type="range"]:focus {
    // outline: none;
  }

  .fill {
    position: absolute;
    top: 66px;
    left: 3px;
    height: 16px;
    width: 16px;
    border-radius: 20px;
    background-color: $accent100;
    // z-index: 100010;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 24px;
    cursor: pointer;
    animate: 0.2s;
    //background: transparent;
    background: #c2cddd;
    border-radius: 50px;
    padding: 2px;

    &:focus {
      background: rgba(87, 56, 255, 0.2);
    }
  }

  input[type="range"]::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50px;
    background: $accent100;
    border: 2px solid white;
    cursor: pointer;
    -webkit-appearance: none;
    position: relative;
  }

  input[type="range"]::-moz-range-thumb,
  input[type="range"]::-ms-thumb {
    height: 24px;
    width: 24px;
    border-radius: 50px;
    background: $accent100;
    cursor: pointer;
  }

  input[type="range"]::-moz-range-track,
  input[type="range"]::-ms-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    animate: 0.2s;
    background: rgba(87, 56, 255, 0.2);
    border-radius: 5px;
  }
}

.info-popup {
  display: none;
}

.arrow-up {
  display: none;
}

.bluebox {
  display: flex;
  gap: 1rem;
  border-radius: 12px;
  border: 1px solid #1f6cf9;
  background: #f4f8ff;
  padding: 1rem;

  .left {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;

    .btitle {
      color: #1f6cf9;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
    }

    .bsubtitle {
      color: #272c34;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }

  .right {
    padding: 0.5rem;
    background-color: white;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;

    .subr,
    .subl {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .tit {
      color: #272c34;
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 150%;
    }

    .number {
      color: #0047CC;
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 150%;
    }

    .subl {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
    }
    .subr {
      text-align: right;

      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
    }

    > div {
      &:first-child {
        border-right: 1px solid #c2cddd;
        padding-right: 1rem;
        margin-right: 1rem;
      }
    }
  }
}

.aumento-contatore {
  .box {
    background-color: #f7f8fb;
    border-radius: 1rem;
    padding: 1rem;

    .power {
      color: #c2185b;
      text-overflow: ellipsis;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      letter-spacing: 0px;
    }

    .description {
      color: #667790;
      text-overflow: ellipsis;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0px;
    }
  }
}

#thanks {
  background-color: white;

  &.fs {
    position: fixed;
    background-color: white;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99999;
  }
  // min-height: 100vh;
  .logo {
    height: 32px;
  }

  main {
    padding: 2rem 0;
  }

  .header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: $newPrimary;
    height: 64px;

    img {
      margin-top: 16px;
      margin-bottom: 16px;
      margin-left: 16px;
    }
  }

  .title {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.5rem;
    color: #272c34;
    margin-bottom: 8px;
    margin-top: 32px;
  }

  .subtitle {
    font-size: 1.125rem;
    color: $gray100;
    line-height: 1.75rem;
  }

  .container {
    padding-top: 64px;
    padding-left: 24px;
    padding-right: 24px;
    text-align: center;
    height: 100%;

    img {
      // margin-bottom: 55px;
    }
  }
}

#landing {
  padding-bottom: 80px;
  position: relative;
  background-color: $newPrimary;

  header .title {
    z-index: 11;
    h2 {
      margin-bottom: 0;
    //   line-height: 3.375rem;
    //   font-size: 2.5rem;
     }
  }
  .landing-box {
    position: relative;
    background-color: $newPrimary;
    .landing-row-component {
      .landing-row-image {
        width: 123px;
        height: 123px;
        margin-right: 1rem;
      }

      .landing-row-title {
        margin-bottom: 0.5rem;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 150%;
      }

      .landing-row-content {
        font-size: 1rem;
        letter-spacing: 0px;
        line-height: 150%;
      }
    }
  }

  .blue-bg {
    background: $newPrimary;
    height: 100vh;
  }
  .col-2,
  .col-md-1 {
    background: $newPrimary;
    min-height: 100vh;
  }

  .landing-text {
    position: relative;
    padding: 16px;
    padding-bottom: 28px;
    font-size: 1.125rem;
    line-height: 1.75rem;
    letter-spacing: 0em;
    text-align: left;
    z-index: 3;

    .list-item-title {
      font-weight: 700;
      font-size: 1.5rem;
      margin-bottom: 4px;
      margin-top: 16px;

      div:first-child {
        margin-right: 8px;
      }
    }

    #info {
      cursor: pointer;
    }
  }

  #ria {
    // padding: 16px 0;
    width: 250px;
    object-fit: contain;
  }

  .small-text {
    font-size: 1rem;
  }

  .smaller-text {
    font-size: 0.75rem;
    margin-top: 24px;
    line-height: 1.125rem;
  }
}

main {
  // padding-bottom: 80px;
}
.arrow-up {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #ffffff;
  position: absolute;
  top: 15px;
  right: -5px;
  z-index: 100;
}

.info-box {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #313b49aa;
  z-index: 10001;

  .content {
    padding: 16px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    z-index: 1001;
    margin-right: 0px !important;
    overflow-y: auto;
    max-height: 100vh;

    .title {
      margin-top: 0px;
      font-size: 1.5rem;
      margin-bottom: 8px;
      font-family: "Roobert";
      font-style: normal;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2rem;

      /* neutrals/neutral-black */

      color: #000103;
    }

    .text {
      font-family: "Roobert";
      font-size: 0.875rem;
      margin-bottom: 16px;
      line-height: 1.25rem;
      font-weight: 400;
      color: #000103;
    }

    a {
      color: $accent100;
    }
  }
}

.big-accent {
  background-color: $accent100;
  color: white;
  border-radius: 30px;
  height: 48px;
  width: 100%;
  border: 0px;
  font-weight: 700;

  &:focus {
    border: 4px double white;
  }
}

.scaldaquabox {
  position: relative;
  :before {
    content: "";
    width: 32px;
    height: 32px;
    position: absolute;
    left: 32px;
    top: 16px;
    background: url("/icons/icon/acquacalda.svg");
  }
  div {
    border: 1px solid #c2cddd;
    border-radius: 16px;
    height: 64px;
    line-height: 4rem;
    padding-left: 64px;
    // margin-left:16px;
    // margin-right: 16px;
  }
}

.selectatview-component {
  .title {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .icon-select {
    width: 32px;
    height: 32px;
  }

  .image-select {
    width: 128px;
    height: 128px;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  .choice-box {
    margin-top: 16px;
    overflow: hidden;
    font-weight: 700;
    font-size: 1rem;
    border: 1px solid #c2cddd;
    border-radius: 16px;


    button {
      border: 1px solid #c2cddd;
      background-color: white;
      border-radius: 16px;
      outline: none;
      &:first-child {
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
      }

      &:last-child {
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
      }
    }

    button.no-bold {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

    button.selected {
      border: 1px solid $accent100 !important;
      background-color: #F7F8FB;
      .box-component {
        background-color:  #F7F8FB;
      }

      &:first-child {
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
      }

      &:last-child {
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
      }
    }

    .single-box {
      border-bottom: 1px solid #c2cddd;
      padding: 16px;
      img {
        filter: grayscale(1);
      }

      &.border-fix {
        border-radius: 1rem !important;
        border: 1px solid black !important;
      }

      &.selected {
        img {
          filter: unset;
        }
        // background: #f4f8ff;
        color: $accent100;
        background: #f7f8fb;
        border: 1px solid #272c34;
        // border-bottom: 0;
        &:last-child {
          border: 1px solid #272c34;
        }

        input[type="radio"] {
          position: relative;
          &::after {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
          }
        }
      }

      &:last-child {
        border-bottom: 1px solid #c2cddd;
        // border-bottom: 0px;
        // border: 1px solid $accent100;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        // border-radius: 16px;
      }

      &:first-child {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }

      input {
        margin-right: 16px;
        display: inline-block;
      }

      img {
        margin-right: 16px;
        margin-left: 16px;
        display: inline-block;
      }

      &:focus-within {
        border: 1px solid $accent80 !important;
      }
    }
  }
}

.percentage-component {
  position: relative;

  .input-border-half {
    border: 1px solid #c2cddd;
    border-radius: 4px;
    padding: 0px 16px 0px 8px;
    height: 40px;
    max-width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #313b49;

    &.dis {
      background-color: #eff2f7;
    }

    input {
      outline: none;
      border: 0;
      flex-grow: 1;
      color: #313b49;
    }

    &.active {
      .prop-name {
        font-size: 0.75rem;
        line-height: 1rem;
        position: absolute;
        display: block;
        left: 22px;
      }

      .prop-value {
        font-size: 1rem;
        line-height: 1.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 30px;
        padding-top: 10px;
      }
    }
  }

  .disclaimer {
    font-size: 0.875rem;
  }

  .subtitle {
    width: 100%;
    max-width: 300px;
    text-align: right;
    font-size: 0.875rem;
    color: #667790;
  }

  .error {
    color: red;
    font-size: 0.875rem;
  }
}

.info-button {
  display: flex;
  position: relative;
  background: unset;
  border: 0;
}
.main.row {
  // padding-right: 16px;
  --bs-gutter-x: 0;
  /* --bs-gutter-y: 0; */
}

.info-popup {
  .big-accent {
    display: none;
  }
}

.address-input {
  line-height: 1rem;
  height: 44px;
  padding: 8px;
  border: 1px solid #c2cddd;
  border-radius: 4px;
  max-width: 400px;
  width: 100%;

  > div {
    border: 1px solid #c2cddd;
    // color: #313B49 !important;
  }

  [class*="placeholder"] {
    // background-color: red;
    color: #313b49;
  }

  input {
    color: #313b49;
  }
}

.simulazione3 {
  display: flex;
  flex-direction: row;
}

#spinner {
  color: #0047CC10;
  border-right-color: #0047CC;
  height: 80px;
  width: 80px;
  --bs-spinner-border-width: 8px;
}

.risparmio-box {
  padding: 1rem;
  // margin-top: 24px;
  border-bottom: 2px solid #c2cddd;
  // border-radius: 12px;
  // padding: 16px;


  .tag {
    background:#F4F8FF;
    border: 1px solid #1F6CF9;
    border-radius: 4px;
    font-size: 0.75rem;
    padding: 1px 4px;
    line-height: 0.75rem;
    display: inline;
    margin-bottom: 0.5rem;
  }


  .top {
    // padding-bottom: 8px;
  }

  .title {
    font-weight: 700 !important;
    font-size: 1rem !important;
    line-height: 1rem !important;
    color: #313b49 !important;
  }

  .img-container {
    width: 80px;
    min-width: 80px;
    min-height: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: rgba(87, 56, 255, 0.08);
    border-radius: 8px;
    margin-right: 16px;

    img {
      height: 24px;
      width: 24px;
    }
  }

  .risparmio-details {
    background: #f7f8fb;
    border-radius: 12px;
    padding: 8px;
    margin-top: 0px;
  }

  .risparmio {
    text-align: right;
    border-left: 1px solid #c2cddd;
    padding-left: 16px;
    margin-left: 16px;

    &.no-border {
      border-left: unset;
    }
  }

  .b {
    font-weight: 700;
  }

  .s {
    text-decoration: line-through;
  }

  .g {
    color: #00855d;
  }
}

#risparmio-preliminare {
  .warning {
    background-color: #f77b001a;
    padding: 20px;
    padding-left: 0px;
    border-left: 4px solid #e67300;
  }

  .risparmio-buttons {
    button {
      min-width: 230px;
    }
  }

  

  .risparmio-box-total {
    background: #f4f8ff;
    border: 1px solid #1f6cf9;
    border-radius: 0.75rem;
    padding: 1rem;

    .title {
      color: #1f6cf9;
    }

    .risparmio-details {
      background: #ffffff;
    }

    .green {
      color: #00855d;
    }
    .g,
    .b {
      color: #1f6cf9;
    }
  }
}

.btn.outline {
  color: $accent100;
  background: unset;
  border: 2px solid $accent100;
  height: 48px;
  font-weight: 700;
  font-size: 1rem;
  width: 100%;
  border-radius: 24px;
}

.btn.filled {
  color: white;
  background: $accent100;
  height: 48px;
  font-weight: 700;
  font-size: 1rem;
  width: 100%;
  border-radius: 24px;
}

.claim {
  color: #d3135a;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
}

.no-border {
  border: 0px !important;
  position: relative;
}

.baloon {
  display: flex;
  justify-content: center;
  align-items: center;
  input {
    min-width: 100px;
  }
}

.thumb {
  position: absolute;
  background-color: white;
  top: 25px;
  height: 20px;
  width: 20px;
  transform: rotate(45deg);
  border-radius: 3px;
  z-index: 1;
  left: 10px;

  // border-left: 20px solid transparent;
  // border-right: 20px solid transparent;
  // border-top: 20px solid rgb(255, 255, 255);
  // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
  //   0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  border: 1px solid #c2cddd;

  &:after {
    position: absolute;
    top: -4px;
    left: -10px;
    right: 0;
    content: "";
    background-color: rgb(255 255 255);
    transform: rotate(-45deg);
    width: 34px;
    height: 15px;
  }
}

.slider-container {
  position: relative;
  margin-top: 16px;
  max-width: 344px;
}

// .marker{
//   margin-top:20px;
//   flex-grow: 1;

//   .line {
//     margin: 0 auto;
//     height: 8px;
//     width: 1px;
//     background-color: gray;
//   }
// }
.fac-simile-copy-component {
  font-size: 1rem;
  h2 {
    font-size: 1rem;
  }
}
.fac-simile-copy-list {
  list-style-type: disc !important;
  padding-left: 2rem !important;
  li {
    margin: 1rem 0
  }
 
}

.divider {
  border-bottom: 1px solid #c2cddd;
  padding-bottom: 24px;
  height: 100%;
}

.bold-title {
  color: #131416 !important;
  font-size: 1.25rem !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 150% !important;
  letter-spacing: 0.4px !important;
}

.color-primary {
  color: $accent100 !important;
}

.disa {
  opacity: 0.6;
  // .mb-16.pre {
  //   opacity: 0.6;
  // }
  // .sliderwiththumb .shad1 {
  //  background-color: #EFF2F7;;
  // }

  // .thumb {
  //   background-color: #EFF2F7;
  //   &::after {
  //     background-color: #EFF2F7;

  //   }
  // }
}
.sliderwiththumb {
  position: relative;
  max-width: 344px;

  .unit {
    font-weight: 400;
    font-size: 1rem;
    // line-height: 1rem;
    margin-right: 8px;
    margin-left: 0px;
    color: #272c34;
    z-index: 100;
    position: relative;
    margin-bottom: 4px;
  }

  // .fill {
  //   background: $accent100;
  //   height: 16px;
  //   border-radius: 8px;
  //   position:absolute;
  // }

  .markers {
    display: flex;
    justify-content: space-between;
    div {
      margin-top: 4px;
      font-weight: bold;
      font-size: 0.75rem;
      line-height: 0.75rem;
      color: #667790;
      position: relative;
      &:before {
        content: "";
        top: -10px;
        left: 50%;
        width: 1px;
        height: 8px;
        background-color: #667790;
        position: absolute;
      }
    }
  }
}

// .container .carousel .carousel-indicators
.modal-pictures 
{
  .accordion {
    .accordion-item {
      border: 0;
      margin-bottom: 0.5rem;
      border-radius: 0.5rem;
      // background-color: #D6E9E1;

      a {
        color: black;
      }

      .accordion-button, .accordion-collapse  {
        background-color: #EFF2F7;
      }

      .accordion-button:not(.collapsed) {
       
        color: black;
      }
    }
  }
  .container-fluid .carousel .carousel-indicators button {

    background-color: $gray60 !important;
    width: 8px;
    height: 8px;
    border: 0;
    border-radius: 8px !important;
    &.active {
      background-color: $accent100 !important;
      border-radius: 8px !important;
      width: 24px;
    }
  }
}

.error-message {
  color: #ed0529;
  font-size: 0.875rem;
  font-weight: 400;
  margin-top: 8px;
  margin-bottom: 8px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: none;
  filter: invert(42%) sepia(100%) saturate(6855%) hue-rotate(247deg)
    brightness(99%) contrast(104%);
}

#bolletta-carousel {
  margin-top: 16px;
}

.no-bold {
  font-family: "Roobert";
}
.accept-final:focus-within {
  border: 1px solid $accent100 !important;
}

// .force-mobile {
//   display: block !important;
//   max-width: 440px;
// }

// .d-none.d-lg-flex.force-mobile {
//   display: none !important;
// }

.progress-desktop {
  flex-wrap: wrap;
}
.desktop-step {
  font-size: 0.75rem;
  font-weight: 400;
  opacity: 0.6;
  flex: 1 1 0px;
  min-width: 60px;
  max-width: 60px;
  &.active {
    max-width: unset;
    flex-grow: 1;
  }

  .step-copy {
    max-width: 80%;
  }

  &.last {
    .step-box {
      justify-content: unset;
      &:after {
        display: none;
      }
    }
  }

  .step-box {
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      content: "";
      width: 100%;
      height: 4px;
      border-radius: 4px;
      background-color: white;
      margin: 0 4px;
    }
  }

  &.active {
    font-size: 0.875rem;
    font-weight: 700;
    opacity: 1;

    .step-number {
      color: #0047cc;
      background: white;
    }
  }

  .step-number-box {
    min-width: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 20px;
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    &.past {
      background:white;
    }
  }
  .step-number {
    // font-size:12px;
    font-size: 0.75rem;
    // color: rgba(0, 0, 0, 1);
    background: transparent;
    color: white;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 1.125rem;
    text-align: center;
    line-height: 1.25rem;
    
  }
}

.riepilogo-label {
  span {
    cursor: pointer;
    color: $accent100;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1rem;
    padding: 8px 16px;
    background: #f7f8fb;
    border-radius: 20px;
  }
}

.riepilogo {
  z-index: 999;
  .title-box {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .riepilogo-title {
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.3125rem;
  }

  .title {
    padding: 16px;
  }

  .accordion {
    border-bottom: 1px solid #c2cddd;
    button {
      background-color: unset;
    }
  }
  .accordion:last-of-type {
    border-bottom: 0px;
  }

  .answers {
    border: 1px solid #c2cddd;
    border-radius: 16px;
    margin-bottom: 38px;
  }
}

.contacts {
  .title {
    box-shadow: 0px 0px 16px rgba(102, 119, 144, 0.4);
  }

  .end-button {
    box-shadow: 0px 0px 16px rgba(102, 119, 144, 0.4);
    background: white;
    padding: 16px;
    justify-content: center;

    .btn {
      margin: 0;
    }

    .final-cancel {
      padding: 16px;
      background: #f7f8fb;
      width: 113px;
      border-radius: 20px;
      min-width: 40%;
      text-align: right;
      padding-right: 24px;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1rem;
      // text-transform: uppercase;
      color: $accent100;
    }

    div {
      min-width: 40%;
      text-align: right;
      // padding-right: 24px;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1rem;
      // cursor: pointer;
      text-transform: uppercase;
      color: $accent100;
      button {
        &:disabled {
          cursor: default !important;
          // border: 10px solid red;
        }
      }
    }
  }
  .modal-content {
    // min-height: 100vh;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
  }

  select {
    margin-bottom: 16px;
  }

  .text-component {
    margin-bottom: 16px;
  }

  .btn {
    margin-top: 16px;
    padding: 16px 24px;
    height: 48px;

    background: $accent100;
    border-radius: 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1rem;
    width: 100%;
  }



  .input-error {
    color: #ed0529;
    margin-bottom: 16px;
    font-size: 0.75rem;
    display: flex;
  }
}

.border {
  border: 1px solid #c2cddd;
  border-radius: 16px;
  padding-left: 16px;
  // margin-bottom: 16px;

  .switch-text {
    font-weight: 700;
  }
}

.pr-16 {
  padding-right: 16px;
}

.final {
  .final-body {
    padding-top: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .header {
    // padding-top: 50px;
    // padding-bottom: 90px;
    // background: linear-gradient(0deg, #170a28 -1.25%, #451e7c 100%);
    // color: white;

    .left-side {
      div {
        margin-top: 60px;
        font-weight: 700;
        font-size: 4rem;
        line-height: 5rem;
        /* or 125% */

        letter-spacing: -0.02em;
      }
    }

    .right-side {
      background-color: white;
      border-radius: 16px;
      color: #313b49;
      // padding: 28px 24px;

      .title {
        color: #461e7d;
        font-size: 2rem;
        line-height: 2.5rem;
        margin-bottom: 20px;
      }

      

      .prodotti {
        // margin-top: 24px;

        .product {
          border: 0;
          border-bottom: 1px solid #c2cddd;
          border-radius: 0px;
          // padding: 16px;
          padding-top: 1rem;
          padding-bottom: 1rem;
          margin-bottom: 16px;
          display: flex;
          justify-content: space-between;
          // flex-wrap: wrap;
          align-items: center;
          &:last-of-type {
            margin-bottom: 0;
          }

          .extra-message {
            color:  #FFF;
            font-size: 0.75rem;
            line-height: 0.75rem;
            background-color: #1F6CF9;
            border-radius: 0.5rem;
            padding: 0.25rem 0.5rem;
            text-align: center;
          }
          .img-container {
            display: flex;
            align-items: center;
              img {
                width: 80px;
                height: 80px;
                // margin-right: 16px;
                // margin-bottom: 16px;
                border-radius: 8px;
                background: #f4f4f4;
                object-fit: contain;
                margin-right: 1rem;
                // padding:10px;
              }
        }

          .top {
            // border-bottom: 1px solid #c2cddd;
            overflow-x: hidden;
            flex-grow: 60;
          }

          .bottom {
            flex-grow: 40;
            background-color: #eff2f7;
            border-radius: 12px;
            align-self: stretch;
            padding: 0.5rem;
            margin-left: 0.5rem;
            min-height: 80px;

            .final {
              color: #272c34;
              font-size: 1.125rem;
              font-weight: 700;
              line-height: 150%;
              letter-spacing: 0px;
            }

            b {
              font-size: 1rem;
              font-weight: 700;
              line-height: 1rem;
              letter-spacing: 0px;
              color: #131416;
            }
          }

          .tag {
            color: #131416;
            font-size: 0.75rem;
            line-height: 0.75rem;
            border: 1px solid #1F6CF9;
            border-radius: 320px;
            padding: 4px 8px;
            background: #f4f8ff;
          }

          .product-title {
            font-weight: 700;
            font-size: 1rem;
            line-height: 1rem;
            color: #313b49;
            margin-top: 10px;
            margin-bottom: 8px;
            // text-wrap: nowrap;
            // white-space: nowrap;
            // text-overflow: ellipsis;

            a {
              color: #313b49;
              text-decoration: none;
            }
          }

          a {
            color: #313b49;
            text-decoration: none;
          }

          .light {
            font-size: 0.75rem;
            line-height: 1.125rem;
            color: #667790;

            .qty {
              font-weight: bolder;
            }
          }

          .price {
            text-align: right;

            .final-price {
              font-size: 1.125rem;
              line-height: 1.25rem;
              color: #313b49;
              font-weight: 700;
            }

            .green {
              font-weight: 400;
              font-size: 1rem;
              line-height: 1rem;
              color: #00855d;
            }

            .striked {
              font-size: 1.125rem;
              line-height: 1.25rem;
              text-decoration-line: line-through;
              color: #667790;
            }
          }
        }
      }
    }
  }

  .benefici {
    // padding-top: 80px;
    // padding-bottom: 80px;

    .title {
      font-weight: 700;
      font-size: 3rem;
      line-height: 4rem;
      text-align: center;
      letter-spacing: -0.02em;
      color: #131416;
    }

    .subtitle {
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 2rem;
      text-align: center;
      color: #313b49;
      margin-bottom: 70px;
    }

    .disclaimer {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: center;
      color: #4f4f4f;
      margin-top: 16px;
    }

    .box {
      .box-info {
        color: #d3135a;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1rem;
      }

      .box-content {
        border-bottom: 1px solid #c2cddd;
        padding: 16px;
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
        // flex-direction: column;

        .box-top {
          margin-bottom: 24px;

          .icon-box {
            width: 40px;
            height: 40px;
            // background: rgba(87, 56, 255, 0.08);
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 16px;

            img {
              width: 24px;
              height: 24px;
            }
          }

          .sub {
            color: #272c34;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }
        }

        .box-bottom {
          background: #f7f8fb;
          border-radius: 12px;
          padding: 8px;
          font-size: 1rem;
          line-height: 1.5rem;
          color: #313b49;
          flex-grow: 1;

          .left {
            flex-grow: 1;
            border-right: 1px solid #c2cddd;
            padding-right: 16px;
          }

          .right {
            text-align: right;
            flex-grow: 1;
          }

          .co2 {
            text-align: right;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: end;
            align-items: end;
          }

          .money {
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 2rem;
            color: #313b49;
          }

          .discount {
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.5rem;
            color: #00855d;
          }

          .mb-8 {
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  .final-disclaimer {
    padding-bottom: 5rem;
  }

  .review-or-download {
    .btn.outline.btn-primary {
      max-width: 260px;
      width: 260px;

      &.mr-16 {
        margin-right: 16px;
      }
    }
    // margin-bottom: 110px;
  }

  .book-appointment {
    padding-top: 72px;
    padding-bottom: 72px;
    color: white;
    background: $accent100;

    h2 {
      font-weight: 700;
      font-size: 3rem;
      line-height: 4rem;
    }

    p {
      font-weight: 400;
      font-size: 1.125rem;
      line-height: 1.75rem;
    }

    button {
      font-weight: 700;
      font-size: 1rem;
      line-height: 1rem;
      background-color: white;
      border-radius: 30px;
      height: 48px;
      padding: 16px 23px;
      color: #461e7d;
      border: 0;
    }
  }

  .footer {
    background: linear-gradient(0deg, #170a28 -1.25%, #451e7c 100%);
    height: 30vh;
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
  }
}

.mt-8 {
  margin-top: 8px;
}
.same-height {
  display: flex;
  flex-wrap: wrap;

  .col {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.header-mobile {
  .final-header {
    .header-bar {
      height: 64px;
      background: $newPrimary;
      // background-image: url("/icons/houses.png");
      padding: 16px;
      align-items: start;

      img {
        height: 24px;
      }
    }

    .row.title {
      padding: 24px 0px;
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.5rem;
      color: #131416;
    }
  }

  .sub-header {
    background: #f7f8fb;

    .title {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2rem;
      color: #131416;
      padding-top: 28px;
      padding-bottom: 28px;
    }

    .buttons {
      color: #313b49;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      width: 100%;
      box-shadow: 0px 2px 8px 0px  rgba(102, 119, 144, 0.20);

      button {
        border: 0;
        border-radius: 0;
        height: 40px;
        padding: 0px 24px;
        color: #313b49;
        line-height: 1.25rem;
        // border-right: 1px solid #C2CDDD;
        &:last-of-type {
          border-right: 0;
        }
        &:hover {
          background-color: #fff !important;
          color: #000 !important;
        }

        &.active {
          font-weight: 700;
          background-color: white !important;
          color: $accent100 !important;
          border: 1px solid $accent100;
          border-radius: 4px;
          // height: 40px;
        }
      }
    }

    .prodotti {
      margin-top: 24px;

      background: white;

      .product {
        border: 1px solid #c2cddd;
        border-radius: 12px;
        padding: 16px;
        margin-bottom: 16px;

        .img-container {
          background: #f0eeef;
          height: 80px;
          display: flex;
          justify-content: center;

          img {
            object-fit: contain;
            width: 80px;
            // height: 80px;
          }
        }

        .product-container {
          margin-left: 8px;
        }

        .top {
          border-bottom: 1px solid #c2cddd;
        }

        .tag {
          font-size: 0.75rem;
          line-height: 0.75rem;
          border: 1px solid #1f6cf9;
          border-radius: 4px;
          padding: 2px 4px;
          background: #f4f8ff;
        }

        .product-title {
          font-weight: 700;
          font-size: 1rem;
          line-height: 1rem;
          color: #313b49;
          margin-top: 10px;
          margin-bottom: 8px;
          a {
            color: #313b49;
            text-decoration: none;
          }
        }

        .light {
          font-size: 0.75rem;
          line-height: 1.125rem;
          color: #667790;

          .qty {
            font-weight: bolder;
          }
        }

        .price {
          text-align: right;

          .final-price {
            font-size: 1.125rem;
            line-height: 1.25rem;
            color: #313b49;
            font-weight: 700;
          }

          .green {
            font-weight: 400;
            font-size: 1rem;
            line-height: 1rem;
            color: #00855d;
          }

          .striked {
            font-size: 1.125rem;
            line-height: 1.25rem;
            text-decoration-line: line-through;
            color: #667790;
          }
        }
      }
    }
  }
}

.fascia {
  margin-bottom: 16px;
  margin-top: 16px;
}
.contacts.select-popup .title .field-name {
  margin-top: 0px;
  margin-bottom: 0px;
}

.thanks {
  height: 100vh;
  background-color: $newPrimary;
  color: white;
  .container {
    h2,
    p,
    a {
      color: white;
    }
  }
  .container-fluid {
    background: $newPrimary;
    // background-image: url("/icons/houses.png");

    .row {
      div {
        padding: 21px 16px;
      }
    }
  }

  #tondo-full {
    z-index: 1;
  }

  .container {
    flex-grow: 1;
    img {
      height: 80px;
      width: 80px;
      margin-bottom: 24px;
    }
    h2 {
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.5rem;
      text-align: center;
      color: #131416;
    }
    p {
      font-weight: 400;
      font-size: 1.125rem;
      line-height: 1.75rem;
      text-align: center;
      color: #000103;
      margin-bottom: 32px;
    }
    button {
      height: 48px;
      background: $accent100;
      border-radius: 30px;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1rem;
      // text-transform: uppercase;
      color: #ffffff;
      padding: 0px 76px;
      border: 0px;
    }
  }

  #visita-punti-vendita-button {
    dsy-icon {
      position: relative;
      top: -1px
    }
  }
}

.no-text {
  [class*="singleValue"] {
    display: none;
  }

  .select-box-text {
    display: none;
  }
}
.select-box {
  position: relative;
  padding-top: 8px;

  .select-box-text {
    top: 12px;
    left: 8px;
    position: absolute;
    font-size: 0.75rem;
    color: #667790;
    line-height: 0.75rem;
  }

  select {
    padding-top: 10px;
    padding-left: 8px;
  }
}

[class*="indicatorContainer"] {
  &:after {
    content: "";
    background-color: white;
    // z-index: 100;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 40px;
    background-size: 12px 12px;
    background-image: url("/icons/icon/search2.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
}

.switch-description {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #667790;
  padding-right: 80px;
}

[class*="singleValue"] {
  padding-top: 10px;
}

.pac-container:after {
  content: none !important;
}

.address-picker {
  position: relative;
  max-width: 400px;
  border: 1px solid #c2cddd !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .address-picker {
  }
  .search-lens {
    padding-right: 16px;
    padding-left: 8px;
    position: absolute;
    right: 0px;
    background-color: white;
  }

  .address-input {
    border: 0;
    // &:focus {
    //   border: 1px solid #C2CDDD !important;
    // }
    // &:after {
    //   width: 100px;
    //   content: '';
    //   height: 100px;
    //   background-color: red;
    //   display: inline-block;
    //   position: absolute;
    //   top:0;
    //   left: 0;
    //   right: 0;
    //   bottom:0;
    // }
  }
}

.label-address {
  position: absolute;
  top: 4px;
  z-index: 200;
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: #667790;
  left: 8px;
}

button {
  font-family: "Roobert";
  font-weight: 700;
}

.mr-4 {
  margin-right: 8px;
}

.no-margin {
  margin: 0;
}

.bold {
  font-weight: 700 !important;
}

.btn-group > :not(.btn-check:first-child) + .btn {
  margin-left: 0;
}

.btn-group > .btn {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border-top-left-radius: 4px !important;
}

input[type="radio"] {
  width: 16px;
  height: 16px;
}

input[type="radio"]:checked {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    background: url("/icons/icon/radio-fill-new.svg");
    background-size: cover;
  }
}

.selected {
  input[type="radio"] {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      background: url("/icons/icon/radio-fill-new.svg");
      background-size: cover;
    }
  }
}

.fonti-text {
  font-family: "Roobert";
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #000103;
}

.fonti-sub {
  font-family: "Roobert";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #000103;
  margin-bottom: 8px;
}

.fonti-facsimile {
  color: $accent100 !important;
  font-weight: 700;
  margin-bottom: 24px;
  line-height: 1rem;
  font-size: 1rem;
  font-family: "Roobert";
  text-decoration: none;
  cursor: pointer;
  display: flex;

  span {
    margin-right: 0.5rem;
  }
}

.mw40 {
  // min-width: 40px;
  margin-left: 14px;
  // justify-self:center;
}

.bool-nonso {
  input {
    border: 2px solid #667790;
    border-radius: 4px;
    margin-right: 8px;
  }

  label {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1rem;
  }
}

@media (max-width: 1400px) {
  .desktop-step {
    font-size: 0.625rem;
    &.active {
      font-size: 0.625rem;
      font-weight: 700;
      opacity: 1;
    }

    &.past {
      opacity: 1;
    }
  }
}

#final-message {
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #313b49;
}

.spacer {
  height: 1px;
  // background:#C2CDDD;
  display: block;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    height: 1px;
    background: #c2cddd;
    display: block;
    top: 0;
    left: calc(var(--bs-gutter-x) * 0.5);
    right: calc(var(--bs-gutter-x) * 0.5);
  }
}

.my-16 {
  margin-top: 16px;
  margin-bottom: 16px !important;
}

#teconsent {
  a {
    color: white;
    display: none;
  }
}
#truste-consent-content,
#truste-consent-buttons,
#truste-consent-track {
  z-index: 10001 !important;
}

.selectatview-component.boxatview {
  .selected {
    border: 1px solid $accent100;
  }

  button {
    &:focus,
    &:active {
      border: 1px solid $accent40 !important;
    }
  }
}

.vertical-tags {
  .tags {
    flex-direction: column;
    align-items: flex-start !important;
    .tag {
      margin-bottom: 0.25rem;
    }
  }
}

.pricing-box {
  p {
    width: 100%;
  }
}
.box-component {
  border-radius: var(--card-radius-A-top-left, 16px)
    var(--card-radius-B-top-right, 16px) var(--card-radius-C-bot-right, 16px)
    var(--card-radius-D-bot-left, 16px);
  border: var(--Border-border-width, 1px) solid
    var(--Border-border-color, #c2cddd);
  background:  #fff;
  padding: 16px;
  box-shadow: 0px var(--shadow-size, 0px) var(--shadow-blur, 0px) 0px
    var(--shadow-color, rgba(255, 255, 255, 0));
  font-weight: 400;
  font-family: "Roobert";



  &.disabled {
    opacity: 0.6;
  }
  img {
    max-width: 128px;
    max-height: 128px;
    border-radius: 0.5rem;
  }
  .title {
    font-size: 0.875rem;
    line-height: 0.875rem;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0px;
    margin-bottom: 4px;
  }

  .serie {
    color: #667790;
    font-size: 0.75rem;
    line-height: 0.75rem;
    margin-bottom: 10px;
  }



  .tags {
    display: flex;
    margin-bottom: 16px;
    align-items:center;

   

    .tag {
      background-color: #1f6cf9;
      color: white;
      border-radius: 1rem;
      padding: 2px 4px;
      font-size: 0.75rem;
      line-height: 0.75rem;
      margin-right: 4px;
    }
  }

  &.yellow-tags {
    .tag {
      background-color: #F5B500;
      color:#131416;
    }
  }
  .description {
    font-size: 0.75rem;
    line-height: 0.75rem;
  }
}

[class*="-menu"] {
  z-index: 10000 !important;
}
.yellow-tags {
  .tag {
    background-color: #F5B500  !important;
    color:#131416 !important;
  }
}
.selectatview-component .choice-box.horizontal {
  display: flex;
  flex-direction: row;
  // gap: 24px;
  border: 0px !important;
  .choice-box .single-box {
    border-bottom: 1px solid #c2cddd;
  }

  label {
    &.first {
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    &.last {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }
}

#tondo {
  width: 478px;
  height: 671px;
  background-image: url("/icons/banner/tondo.png");
  background-repeat: no-repeat;
  position: absolute;
  right: 0px;
  bottom: -20px;
  z-index: -1;
}

#tondo-full {
  width: 956px;
  height: 671px;
  background-image: url("/icons/banner/tondo-full.png");
  background-repeat: no-repeat;
  position: absolute;
  left: 100px;
  bottom: 0px;
  z-index: 2;
}

.disclaimer-component {
  max-width: 600px;
  margin-bottom: 24px;
  .disclaimer-title {
    color: #131416;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.4px;
  }

  .disclaimer-subtitle {
    color: #667790;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0px;
  }
}

#back-button2 {
  max-width: 130px;
}


.buttons {
  color: #313b49;
  button {
    border: 0;
    margin-left: 9px;
    border-radius: 0;
    height: 40px;
    color: #313b49;
    box-shadow: none !important;

    &::after {
      content: "";
      position: absolute;
      right: -5px;
      height: 24px;
      width: 1px;
      background-color: #c2cddd;
      // z-index: -1;
    }

    // border-right: 1px solid #C2CDDD;
    &:last-of-type {
      border-right: 0;
      &::after {
        display: none;
      }
    }

    &:hover {
      background-color: white !important;
      color: $accent100;
      &.active {
        background-color: $accent100 !important;
        color: white;
      }
    }
    &:focus {
      background-color: white !important;
      color: $accent100;
      &.active {
        background-color: $accent100;
        color: white;
      }
    }

    &.active {
      // font-weight: 700;
      background-color: $accent100 !important;
      color: white;
      border: 1px solid $accent100 !important ;
      border-radius: 4px;
      height: 40px;
    }
  }
}

.light-disclaimer {
  max-width: 700px;
  border: 1px solid #C2CDDD;
  padding: 1rem;
  border-radius: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  width: 90%;
  margin-top: 2.5rem;
  p {
    margin-bottom: 0;
  }

  .light-disclaimer-title {

    font-size: 1.125rem;
    line-height: 24px;
    font-weight: 700;
    display: flex;
    align-items: center;

    img {
      margin-right: 0.5rem;
    }
  }
}